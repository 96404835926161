//
// Footer
//

.text-section-footer-block {
  background-color: $black;
  color: $white;
  padding: ($gutter*2) 0 ($gutter*4);

  h3.subtitle {
    border-color: #e5e5e5;
    color: $white;
  }

  h3 a {
    color: $gray-2;
    text-decoration: underline;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.constrained {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

#footer {
  background-color: $black;
  color: $gray-4;
  @include font-size(14px);
  padding-bottom: $gutter;
  padding-top: $gutter;

  img {
    max-height: 60px;

    @media (max-width: 767.98px) {
      max-height: 30px;
    }
  }

  ul.navs {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;

    li { display: inline; }

    @media (max-width: 767.98px) {
      columns: 2;
      display: block;

      li { display: block; margin-bottom: 10px;}
    }
  }

  a {
    color: $gray-4;
    text-decoration: none;
  }

  svg {
    fill: $gray-4;
    max-height: 14px;
  }

  nav {
    a {
      color: $gray-2;
      margin-right: $gutter;
      text-decoration: none;

      &:hover { text-decoration: underline; }
    }
  }

  ul.social {
    list-style: none;
    margin: 0;
    padding: 0;

    li { display: inline; }
  }
}
