//
// Colors

$white: #fff;
$black: #282828;

$gray-1: #efeeeb;
$gray-2: #c4c4c4;
$gray-3: #a8a8a8;
$gray-4: #626264;

$lt-orange: #ffe6c5;
$orange: #f96621;

$blue: #005120;
