//
// Buttons
//

.btn-block {
  display: block;
  width: 100%;
}

.btn-primary {
  background-color: $white;
  border-color: $white;
  border-radius: 60px;
  color: $gray-4;
  font-family: "MaisonBook";
  @include font-size(14px);
  padding: calc($gutter/1.5) ($gutter*2);

  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    border-color: $white;
    color: $gray-3;
  }

  &.btn-xxl {
    border-radius: 500px;
    @include font-size(24px);
    padding-bottom: $gutter*3;
    padding-top: $gutter*3;

    @media (max-width: 767.98px) {
      padding-bottom: $gutter*1.5;
      padding-top: $gutter*1.5;
    }
  }

  @media (max-width: 767.98px) {
    display: block;
    width: 100%;
  }
}

.btn-outline-primary {
  border-color: $white;
  border-radius: 60px;
  color: $white;
  @include font-size(14px);
  padding: calc($gutter/3) ($gutter*1.25);

  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    border-color: $white;
    color: $gray-4;
  }

  // dark option
  .page--contact &,
  .page--careers &,
  .blog &,
  .post &,
  .page--privacy-policy &,
  .page--terms-of-service & {
    border-color: $gray-4;
    color: $gray-4;

    &:hover,
    &:active,
    &:focus {
      background-color: $gray-4;
      border-color: $gray-4;
      color: $gray-1;
    }
  }
}

// dark optoin on scroll
html:not([data-scroll='0']) #header .btn-outline-primary {
  border-color: $gray-4;
  color: $gray-4;

  &:hover,
  &:active,
  &:focus {
    background-color: $gray-4;
    border-color: $gray-4;
    color: $gray-1;
  }
}

.btn-secondary {
  background-color: $white;
  border-color: $white;
  border-radius: 60px;
  box-shadow: 6px 4px 20px 0px #D4D1C8;
    -webkit-box-shadow: 6px 4px 20px 0px #D4D1C8;
  color: $gray-4;
  font-family: "MaisonBook";
  @include font-size(14px);
  padding: calc($gutter/1.5) ($gutter*2);

  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    border-color: $white;
    color: $gray-3;
  }

  @media (max-width: 767.98px) {
    display: block;
    width: 100%;
  }
}

.block--link {
  border: 1px solid #A8A8A8;
  @include border-radius(5px);
  color: $gray-4;
  display: block;
  min-height: 240px;
  padding: $gutter;
  position: relative;
  text-decoration: none !important;

  h6 {
    text-decoration: none;
  }

  span {
    bottom: $gutter;
    color: #8E8E8E;
    font-family: "FlechaReg";
    @include font-size(30px);
    letter-spacing: 0;
    position: absolute;
    text-decoration: underline;
  }
}

.btn-hamburger {
  appearance: none;
  background-color: transparent;
  border: none;
  height: 16px;
  position: relative;
  top: -6px;
  width: 20px;

  span {
    background-color: $white;
    display: block;
    height: 2px;
    position: absolute;
    top: 0;
    transition: all 250ms ease-in-out;
    width: 20px;

    &:nth-child(2) {
      top: 7px;
    }

    &:nth-child(3) {
      bottom: 0;
      top: auto;
    }
  }

  &[aria-expanded="true"] {
    span {
      top: 6px;
      transform: rotate(45deg);

      &:nth-child(2) { display: none; }

      &:nth-child(3) {
        bottom: auto;
        top: 6px;
        transform: rotate(-45deg);
      }
    }
  }

  .blog &,
  .post & {
    span { background-color: $gray-4; }
  }
}

#header.nav--open .btn-hamburger span {
  background-color: $white !important;
}

// dark option
html:not([data-scroll='0']) .btn-hamburger span {
  background-color: $gray-4;
}
