//
// Slices
//

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.featured--img {
  background-color: $black;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 100%;
}

.alt--bg_true {
  background-color: $gray-1;
}

.border-t {
  border-top: 1px solid $gray-2;
}

.content-section {
  padding-bottom: $gutter;
  padding-top: $gutter;
  position: relative;

  a:not(.btn,.block--link) {
    color: $gray-4;
    text-decoration: underline;

    &:hover { text-decoration: none; }
  }

  @media (max-width: 767.98px) {
    padding-bottom: $gutter;
    padding-top: $gutter;
  }
}

section a {
  color: $gray-4;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: $gray-4;
    text-decoration: none;
  }
}

.back--link {
  @include font-size(14px);
  text-decoration: none;
}

.read--more {
  border-bottom: 2px solid #E5E5E5;
  color: $gray-4;
  padding-bottom: 5px;
  text-decoration: none;
  transition: all 250ms ease-in-out;

  &:hover {
    color: $gray-4;
    border-color: $gray-4;
  }

  &.ext {
    position: relative;

    &::after {
      background-image: url('../_img/icon-external-link.svg');
      background-repeat: no-repeat;
      background-size: auto 20px;
      content: '';
      height: 20px;
      margin-left: 8px;
      opacity: 0.5;
      position: absolute;
      top: 2px;
      width: 20px;
    }
  }
}

.blog .post { margin-bottom: 5rem; }

.cover--photo {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 300px;
  width: 100%;
}

.featured--image {
  position: relative;

  figcaption {
    bottom: 0;
    position: absolute;
    right: -$gutter*1.5;
    transform: rotate(90deg);
    transform-origin: 100% 0;
  }
}

.heading {
  padding-bottom: 0;
  padding-top: $gutter*4 !important;
}

.standard-text {

  h2,h3,h4,p {
    max-width: 80%;

    @media (max-width: 767.98px) {
      max-width: 100%;
    }
  }

  p.block-img {

    img { width: 100%; margin-bottom: $gutter*2;}
  }

  h3 {
    margin-bottom: $gutter*3;
  }

  h5 {
    border-top: 1px solid $gray-2;
    color: $gray-4;
    font-family: "MaisonBook";
    @include font-size(18px);
    letter-spacing: 0;
    margin-bottom: $gutter*3;
    padding-top: calc($gutter/2);
  }

  ul {
    list-style: none;
    margin: ($gutter*2) 0;
    max-width: 60%;
    padding: 0;

    li {
      margin-bottom: calc($gutter/2);
      padding-left: 25px;
      position: relative;

      &::before {
        content: "";
        border: 1px solid $gray-4;
        border-radius: 50%;
        height: 13px;
        left: 0;
        position: absolute;
        top: 8px;
        width: 13px;
      }
    }
  }
}

.left-text-section,
.right-text-section,
.split-callout-section {

  p {
    margin-bottom: 1.75rem;

    em {
      @extend .caption;
      display: block;
      font-style: normal;
      margin-bottom: $gutter*2;
      text-transform: uppercase;
    }

    &.block-img {
      img {
        max-width: 400px;

        @media (max-width: 767.98px) {
          max-width: 300px;
          width: 100%;
        }
      }
    }
  }

  h3 {
    position: relative;

    em {
      font-style: normal;
    }
  }

  h3,
  h4 { margin-bottom: $gutter*3;}

  h5 {
    @extend .caption;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .callout {
    img {
      float: left;
      height: 40px;
      margin-right: calc($gutter/2);
      position: relative;
      top: -8px;

      @media (max-width: 767.98px) {
        height: auto;
        margin-right: $gutter;
        width: 68px;
      }
    }

    p {
      @extend .caption;
    }
  }

  .homepage & {
    h4 {
      margin-bottom: 1rem;
    }
  }
}

.page--solutions .right-text-section {
  h3 {
    font-family: "MaisonBook";
    @include font-size(14px);
    letter-spacing: 0;
    margin-left: 25px;
    position: relative;

    &::before {
      content: "";
      border: 1px solid $gray-4;
      border-radius: 50%;
      height: 13px;
      left: -25px;
      position: absolute;
      top: 1px;
      width: 13px;
    }
  }
}

.text-section-ghost {
  //background-image: url('/satellite-path.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: auto;

  @media (min-width: 1700px) {
    background-position: 50% 0;
  }

  #ghost {
    position: absolute;
    right: 0;
    top: -100px;

    @media (max-width: 820px) {
      top: -60px;
    }

    @media (max-width: 767.98px) {
      top: -40px;
    }

    img {
      width: 550px;

      @media (max-width: 820px) {
        width: 400px;
      }

      @media (max-width: 767.98px) {
        width: 200px;
      }
    }

    figcaption {
      bottom: 0;
      position: absolute;
      right: 0;
      transform: rotate(90deg);
      transform-origin: 0 100%;

      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }

  .satLat,
  .satLng {
    display: inline-block;
    font-family: "MaisonMono";
    letter-spacing: 0.5px;
    max-width: 72px;
    overflow: hidden;
    position: relative;
    top: 3.5px;
  }

  h3.subtitle {
    padding-bottom: $gutter*4;

    @media (max-width: 767.98px) {
      padding-bottom: $gutter;
    }

  }

  h4.detail {
    padding-left: 0;
  }

  h4 {
    margin-bottom: 2rem;
  }

  span.sat--location {
    padding-left: calc($gutter/3);

    @media (max-width: 767.98px) {
      display: block;
      margin-top: 5px;
      padding-left: 0;

      .satLoc { clear: right; }

      .breaker { display: block; text-indent: -999em; height: 5px; }
    }
  }

}

.feature {

  img {
    display: block;
    margin-bottom: $gutter;
    max-height: 80px;
    width: auto;
  }

  h5 {
    @extend .eyebrow;
    padding-left: $gutter*2.5;
    position: relative;

    &::before {
      content: "";
      border-top: 1px solid $gray-4;
      display: block;
      left: 0;
      position: absolute;
      top: 11px;
      width: 54px;
    }
  }
}

.full-width-image {
  img { width: 100%; }

  .page--contact &,
  .page--careers & {
    margin-bottom: $gutter*3;
    padding: 0;

    .container {
      max-width: 100%;
      padding: 0;
      width: 100%;

      .row {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        --bs-gutter-x: 0;
      }
    }
  }
}

.deep-links {

  .row {
    background-color: $white;
  }

  ul {
    border-bottom: 1px solid #8e8e8e;
    border-top: 1px solid #8e8e8e;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline;
      @include font-size(14px);
      margin-right: $gutter*2;
    }
  }

  a {
    text-decoration: none !important;

    &:hover { text-decoration: underline !important; }
  }

  + .content-section { padding-top: 0; }
}

.product-highlight {

  img.feature--icon {
    height: 120px;
  }

  img.img-icon {
    display: inline-block;
    margin-right: calc($gutter/2);
    margin-top: -10px;
    height: 50px;
  }

  .page--solutions & {
    img.feature--icon + p {
      padding-left: 70px;
      position: relative;

      &::before {
        content: "";
        border-top: 1px solid #626264;
        display: block;
        left: 0;
        position: absolute;
        top: 14px;
        width: 54px;
      }
    }
  }
}

.dual-image {
  h3 {
    min-height: 200px;
  }
  h4 {
    min-height: 125px;
  }
}

.columns-section {

  img.feature--icon {
    max-height: 250px;
  }
}

.marquee--wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;

  .marquee--left {
    background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0.00));
    height: 100%;
    left: 0;
    position: absolute;
    width: 60px;
    z-index: 99;
  }

  .marquee--right {
    background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0.00));
    height: 100%;
    right: 0;
    position: absolute;
    width: 60px;
    z-index: 99;
  }
}

.marquee--slider {
  animation: 35s linear infinite slider;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.img-section-logos {
  img {
    margin: 0 60px;
    max-height: 80px;
    max-width: 190px;
  }
}

.list-items {
  ul {
    columns: 3;
    list-style: none;
    margin: 0 0 $gutter;
    padding: 0;

    li {
      margin-bottom: calc($gutter/2);
      padding-left: 20px;
      position: relative;

      &::before {
        content: "";
        border: 1px solid $gray-4;
        border-radius: 50%;
        height: 13px;
        left: 0;
        position: absolute;
        top: 8px;
        width: 13px;
      }
    }

    @media (max-width: 767.98px) {
      columns: 1;
    }
  }

  hr {
    background-color: $gray-2;
    margin: $gutter*4 0 0;
    opacity: 1;
  }

  + .full-width-image {
    padding-top: 0;
  }
}

.numbered-list-items {
  ol {
    counter-reset: approach;
    font-family: 'FlechaReg';
    @include font-size(48px);
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid $gray-2;
      counter-increment: approach;
      padding: ($gutter*2) 0;
      position: relative;
      width: 100%;

      &::before {
        content: counter(approach) ". ";
        padding-right: calc($gutter/2);
      }

      &:last-child { border-bottom: none; }

      p { display: inline; }

      span {
        border-top: 1px solid #8E8E8E;
        display: inline-block;
        margin: 0 $gutter;
        position: relative;
        top: -15px;
        width: 50px;

        + p {
          color: #A8A8A8;
        }
      }
    }

    @media (max-width: 767.98px) {
      font-size: 30px;

      span + p {
        display: block;
        padding-left: $gutter+10px;
      }
    }
  }
}

.header-columns {
  .column-content {
    border-left: 1px solid #8E8E8E;

    h4,p {
      padding-left: calc($gutter/2);
      padding-right: calc($gutter/2);
    }

  }
}

.team-members {

  figure {
    background-position: 50% 10%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 412px;
    overflow: hidden;
    width: 100%;

    @media (max-width: 767.98px) {
      height: 100px;
    }
  }

  h4,h5 {
    font-size: 36px;

    @media (max-width: 767.98px) {
      font-size: 20px;
    }
  }
}

.detail {
  font-family: "MaisonBook";
  @include font-size(14px);
  letter-spacing: 0;
  padding-left: 25px;
  position: relative;

  &::before {
    content: "";
    border: 1px solid $gray-4;
    border-radius: 50%;
    height: 13px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 13px;
  }
}
