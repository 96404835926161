//
// header
//

#header {
  background-color: transparent;
  color: $white;
  height: auto;
  padding-bottom: $gutter;
  padding-top: $gutter;
  position: sticky;
  top: 0;
  transition: all 250ms ease-in-out;
  z-index: 9999;

  .logo {
    background-image: url('../_img/logo-osk.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: auto 42px;
    height: 42px;
    width: 263px;

    @media (max-width: 1180px) {
      background-size: auto 32px;
      height: 32px;
      width: 90px;
    }
  }

  .nav {
    @include font-size(14px);

    a {
      color: $white;
      display: inline-block;
      position: relative;
      text-decoration: none;

      &::before {
        background-color: $white;
        bottom: 0;
        height: 1px;
        position: absolute;
        width: 100%;
      }

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }

  // dark option
  .page--contact &,
  .page--careers &,
  .blog &,
  .post &,
  .page--privacy-policy &,
  .page--terms-of-service & {
    background-color: transparent;
    color: $gray-4;

    .logo {
      background-image: url('../_img/logo-osk-dark.svg');
    }

    .dropdown-toggle::after {
      border-color: $gray-4;
    }

    .nav,
    .dropdown-menu {
      a {
        color: $gray-4;
      }
    }
  }

  // when mobile nav open
  &.nav--open {
    background-color: $black !important;
    height: 100vh;

    .logo {
      background-image: url('../_img/logo-osk-color.svg') !important;
    }
  }

  // drop nav
  .dropdown-toggle {

    &::after {
      border: none;
      border-left: 1px solid $white;
      border-bottom: 1px solid $white;
      height: 6px;
      margin-left: 8px;
      transform: rotate(-45deg);
      transition: all 250ms ease-in-out;
      width: 6px;
    }

    &.show::after {
      border-left: none;
      border-bottom: none;
      border-right: 1px solid $white;
      border-top: 1px solid $white;
    }
  }

  .dropdown-menu {
    background-color: transparent;
    border: none;
    border-radius: 0;
    @include font-size(14px);

    a {
      color: $gray-2;

      &:hover {
        background-color: transparent;
        color: $gray-2;
        text-decoration: underline;
      }
    }
  }
}

// dark option on scroll
html:not([data-scroll='0']) #header {
  background-color: $gray-1;
  color: $gray-4;
  padding-bottom: calc($gutter/2);
  padding-top: calc($gutter/2);

  .logo {
    background-image: url('../_img/logo-osk-dark.svg');
  }

  .nav {
    a {
      color: $gray-4;
    }
  }

  .dropdown-toggle::after {
    border-color: $gray-4;
  }

  .dropdown-menu {
    background-color: $gray-1;
  }
}

.lead--in {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  padding: 200px 0 100px;
  margin-top: -102px;

  @media (max-width: 820px) {
    background-position: 80% 80%;
    padding: 150px 0 50px;
  }

  p {
    max-width: 70%;

    @media (max-width: 820px) {
      max-width: 100%;
    }
  }

  // dark option
  .page--contact &,
  .page--careers & {
    background-color: $gray-1;
    background-image: none !important;
    color: $gray-4;

    h3.subtitle { color: $gray-4; }
  }

  // white bg option
  .blog &,
  .post & {
    background-color: $white;
    background-image: none !important;
    color: $gray-4;

    h3.subtitle { color: $gray-4; }
  }

  &.undefined {
    background-color: $white;
    background-image: none !important;
    color: $gray-4;
  }
}

#desktopNav {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;

  @media (max-width: 820px) {
    display: none !important;
  }
}

// mobile nav
#mobileNav {
  color: $white;
  padding-top: $gutter;

  .nav {
    @include font-size(18px);

    .nav-item {
      margin-bottom: calc($gutter/2);

      &:last-child { margin-bottom: 0; }
    }

    .nav-link {
      color: $white !important;
    }
  }

  .nav--subnav {
    a { color: $gray-2; }
  }
}
