//
// Fonts & Typography
//

// Flecha M Light
@font-face {
    font-family: 'FlechaLight';
    src: url('../_webfonts/FlechaM-Light.woff2') format('woff2'),
         url('../_webfonts/FlechaM-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Flecha M Regular
@font-face {
    font-family: 'FlechaReg';
    src: url('../_webfonts/FlechaM-Regular.woff2') format('woff2'),
         url('../_webfonts/FlechaM-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Maison Neue Book
@font-face {
    font-family: 'MaisonBook';
    src: url('../_webfonts/MaisonNeueWEB-Book.woff2') format('woff2'),
         url('../_webfonts/MaisonNeueWEB-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Maison Neue Mono
@font-face {
    font-family: 'MaisonMono';
    src: url('../_webfonts/MaisonNeueMonoWEB-Regular.woff2') format('woff2'),
         url('../_webfonts/MaisonNeueMonoWEB-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/////////////////////////////////////////////

h1,h2,h3,h4,h5,h6 {
  margin-bottom: 1rem;
}

h1 {
  font-family: "FlechaLight";
  @include font-size(150px);
  letter-spacing: -2px;
  line-height: 0.9;

  &.banner-title {
    @include font-size(110px);

    @media (max-width: 767.98px) {
      font-size: 64px;
    }
  }

  .post & {
    @include font-size(52px);
    line-height: 1.25;

    @media (max-width: 767.98px) {
      font-size: 30px;
    }
  }

  @media (max-width: 767.98px) {
    font-size: 64px;
  }
}

h2 {
  font-family: "FlechaReg";
  @include font-size(110px);
  letter-spacing: -2px;
  line-height: 0.9;

  @media (max-width: 767.98px) {
    font-size: 54px;
  }
}

h3,h4,h5,h6 {
  font-family: "FlechaReg";
  letter-spacing: -1px;

  &.subtitle {
    border-top: 1px solid $gray-2;
    color: $gray-4;
    font-family: "MaisonBook";
    @include font-size(18px);
    letter-spacing: 0;
    padding-top: $gutter/2;

    &.no--border {
      border-top: none;
      color: $white;
      padding-bottom: $gutter*3;
      padding-top: 0;
    }

    .blog & {
      border-color: #8E8E8E;
      color: $gray-4;
    }
  }

  &.detail {
    font-family: "MaisonBook";
    @include font-size(14px);
    letter-spacing: 0;
    position: relative;

    &::before {
      content: "";
      border: 1px solid $gray-4;
      border-radius: 50%;
      height: 13px;
      left: -25px;
      position: absolute;
      top: 1px;
      width: 13px;
    }
  }
}

h3 {
  @include font-size(64px);

  @media (max-width: 767.98px) {
    font-size:40px;
  }
}

h4 {
  @include font-size(48px);

  @media (max-width: 767.98px) {
    font-size: 30px;
  }
}

h5 {
  @include font-size(36px);

  @media (max-width: 767.98px) {
    font-size: 24px;
  }
}

h6 {
  @include font-size(30px);

  @media (max-width: 767.98px) {
    font-size: 20px;
  }
}

/////////////////////////////////////////////

.caption {
  font-family: "MaisonMono";
  @include font-size(16px);
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.eyebrow {
  font-family: "MaisonBook";
  @include font-size(18px);
  letter-spacing: 1px;
}
