//
// repeateable vars
//

$gutter: 30px;

@function calculate-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

  @mixin font-size($size) {
    font-size: $size; //Fallback in px
    font-size: calculate-rem($size);
  }

// easy border radius
// @include border-radius(50%);
@mixin border-radius($radius) {
  border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -webkit-border-radius: $radius;
}

// easy box-shadows
@mixin box-shadow($content) {
  filter: drop-shadow($content rgba(0,0,0,0.2));
    -webkit-filter: drop-shadow($content rgba(0,0,0,0.2));
}

// position text in elements in the center vertically
// @include flex-vert();
@mixin flex-vert() {
  align-items: center;
    -webkit-align-items: center;
  display: flex;
    display: -webkit-flex;
}

// smooth transitions for :hover states, etc
// @inclue transition(color 250ms linear);
@mixin transition($args) {
  transition: $args;
    -ms-transition: $args;
    -moz-transition: $args;
    -webkit-transition: $args;
}

// css columns
@mixin column($content) {
  column-count: $content;
    -moz-column-count: $content;
    -webkit-column-count: $content;
  column-gap: 0;
    -moz-column-gap: 0;
    -webkit-column-gap: 0;
}

// marquee style animation
@keyframes slider {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-205%, 0);
  }
}