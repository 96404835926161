//
// Orbial Sidekick
//

@import '_partials/colors',
        '_partials/vars',
        '_partials/type';

body {
  color: $gray-4;
  font-family: "MaisonBook";
  @include font-size(20px);

  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}

@import '_partials/header',
        '_partials/footer',
        '_partials/buttons',
        '_partials/slices';
